/* @import url(//fonts.googleapis.com/earlyaccess/notosansthaiui.css); */
@import url(//fonts.googleapis.com/earlyaccess/notonaskharabicui.css);

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url(./fonts/NotoSansJP-Regular.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url(./fonts/NotoSansJP-Bold.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(./fonts/NotoSansKR-Regular.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(./fonts/NotoSansKR-Bold.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url(./fonts/NotoSansSC-Regular.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url(./fonts/NotoSansSC-Bold.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Naskh Arabic UI";
  src: url(./fonts/NotoNaskhArabicUI-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Naskh Arabic UI";
  src: url(./fonts/NotoNaskhArabicUI-Bold.ttf) format("truetype");
  font-weight: 500;
}

/* 
@font-face {
  font-family: "Noto Sans Thai UI";
  src: url(./fonts/NotoSansThaiUI-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans Thai UI";
  src: url(./fonts/NotoSansThaiUI-Bold.ttf) format("truetype");
  font-weight: 500;
} */

@font-face {
  font-family: "Noto Sans Thai UI";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Regular.eot);
  src: url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "Noto Sans Thai UI";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Bold.eot);
  src: url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosansthaiui/v3/NotoSansThaiUI-Bold.ttf)
      format("truetype");
}

html {
  background-color: white;
  min-height: 100%;
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  /* height: -webkit-fill-available; */
  -webkit-screen-size-adjust: 100%;
  overscroll-behavior-y: contain;
}

a,
a:visited,
a:hover,
a:active {
  /* color: inherit; */
  text-decoration: none;
}
/* ::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #13131c89;
} */

.ReactVirtualized__List {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gm-style > * {
  box-sizing: content-box;
}

.gm-style .gm-style-iw-c {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  max-width: 340px !important;
  outline: none;
}
.gm-style .gm-style-iw-t::after {
  height: 0;
  width: 0;
}
.gm-style .gm-style-iw-c > button {
  display: none !important;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-style .gm-style-iw-tc {
  display: none;
}

.gps-tracking-event-btn:hover,
.gps-tracking-event-btn:active {
  color: #13131c !important;
  background-color: transparent;
}

.gm-style img[src*="cameraprofile"] {
  border-radius: 50% !important;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: transparent !important;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

/* Tooltip text */
.gps-tracking-event-btn .tooltiptext {
  visibility: hidden;
  /* Light mode/#13131C ‧ 1 */

  background: #13131c;
  /* Shadow 2 ‧ Small UI elements */

  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12),
    0px 0px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #fff;
  text-align: center;
  padding: 4px 8px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translate(-50%, 0);

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.gps-tracking-event-btn .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.gps-tracking-event-btn:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-center .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  display: none !important;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

.mapboxgl-popup {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  will-change: transform;
  pointer-events: none;
  z-index: 90;
}

.vjs-big-play-centered.vjs-fluid {
  padding-top: min(56.25%, 720px) !important;
}

.vjs-loading-spinner {
  display: none !important;
}

.vjs-error-display {
  display: none !important;
}

.ios-map-fullscreen {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1290;
  height: calc(var(--vh, 1vh) * 100) !important;
}
